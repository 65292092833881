import React, { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../theme';
import { Helmet } from 'react-helmet';
import DashOneBody from '../components/DashOne/DashOneBody';
import { connect } from 'react-redux';
import { isMembershipSelected } from '../helpers/Actions';
import SelectBuilding from '../components/SelectBuilding';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  containerContent: {
    width: 'calc(100% - 48px) !important',
    height: 'calc(100vh - 112px)',
    backgroundColor: theme.background,
    padding: '24px',
  },
}));

const DashOne = ({ selMembership }) => {
  const classes = useStyles();
  const cookies = new Cookies();
  const navigate = useNavigate();

  const rotateDashboards = () => {
    const displayRotating = cookies.get('dashsToRotate');
    const indexThisDash = displayRotating.findIndex((item, index) => {
      return item.index === 0;
    });
    console.log('indexThisDash ONE: ', indexThisDash);
    displayRotating.splice(indexThisDash, 1);
    console.log('rotando a : ', displayRotating[0].link);
    navigate(displayRotating[0].link, { replace: true });
  };

  const checkIfDashOneRotate = () => {
    const displayRotating = cookies.get('dashsToRotate')
      ? cookies.get('dashsToRotate')
      : [];

    const flag = displayRotating.filter((d) => d.index === 0).length > 0;
    console.log('flag ONE: ', flag);
    return flag;
  };

  /* useEffect(() => {
    if (selMembership.buildingName) {
      setTitle(
        'Resúmen de prestamos en curso y ranking de morosidad de usuarios'
      );
    } else {
      setTitle('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selMembership]); */

  useEffect(() => {
    //setIsMembershipSel(isMembershipSelected());
    let mounted = true;
    if (
      cookies.get('isDisplayChange') === 'true' &&
      checkIfDashOneRotate() &&
      parseInt(cookies.get('tvNum') ? cookies.get('tvNum') : 0) === 2
    ) {
      console.log('rotando');
      setInterval(() => {
        if (mounted) {
          rotateDashboards();
        }
      }, 330 * 1000);
    }

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selMembership]);

  return (
    <>
      <Helmet>
        <title>Alliot TV</title>
      </Helmet>
      <Box className={classes.containerContent}>
        <Container maxWidth={false}>
          {isMembershipSelected() ? <DashOneBody /> : <SelectBuilding />}
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  selMembership: state.membershipSelectedRed.membership,
});

/* const mapDispatchToProps = (dispatch) => ({
  setTitle(newTitle) {
    dispatch({
      type: 'SET_DASHBOARD_TITLE',
      title: newTitle,
    });
  },
});
 */
export default connect(mapStateToProps, null)(DashOne);
