import React, { useState } from 'react';
import Modal from 'react-modal';
import { makeStyles } from '@mui/styles';
import theme from '../../theme';
import {
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'universal-cookie';

const useStyles = makeStyles(() => ({
  modalFilterContainer: {
    padding: '0px !important',
  },
  modalFilterHeader: {
    maxWidth: 'calc(100% - 8px)',
    marginLeft: '8px !important',
    marginTop: '8px !important',
    backgroundColor: '#EBEEF2',
    color: '#6C757D',
    padding: '16px 24px 16px 24px !important',
    borderRadius: '4px 4px 0px 0px !important',
  },
  modalFilterHeaderText: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  modalFilterContent: {
    padding: '10px 24px 24px 24px !important',
  },
  modalFilterItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0px !important',
  },
  modalFilterBtn: {
    boxShadow: theme.shadows[5],
  },

  modalContent: {
    marginTop: '24px !important',
    paddingBottom: '24px !important',
  },

  pageTextContainer: {
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '5px',
    padding: '10px 20px !important',
    marginBottom: '8px',
    cursor: 'pointer',
  },

  pageTextContainerSelected: {
    backgroundColor: 'rgba(108, 117, 125, 0.3)',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '5px',
    padding: '10px 20px !important',
    marginBottom: '8px',
    cursor: 'pointer',
  },

  pageText: {
    fontSize: '18px',
    fontWeight: 'bold',
  },

  closeBtnModalContainer: {
    position: 'relative',
  },
  closeBtnModal: {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px !important',
    position: 'absolute',
    top: '-8px',
    right: '-16px',
    backgroundColor: 'white',
    color: '#6C757D',
    borderRadius: '100%',
    boxShadow: theme.shadows[5],
    cursor: 'pointer',
  },
  profileTools: {
    fontWeight: 'bold',
  },
  formConfigItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '16px',
  },
  sectionText: {
    width: '100%',
    fontWeight: 'bold',
  },
  inputTvs: {
    marginTop: '16px',
  },
  configContainer: {
    width: '100%',
    padding: '24px',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
  },
  checkBoxPages: {
    marginTop: '16px',
  },
}));

const ModalConfig = ({
  showModalSelectPage,
  closeModalSelectPage,
  displayTitles,
}) => {
  const classes = useStyles();
  const cookies = new Cookies();
  const [isDisplayChange, setIsDisplayChange] = useState(
    cookies.get('isDisplayChange')
      ? cookies.get('isDisplayChange') === 'true'
      : false
  );
  // const [timeChange, setTimeChange] = useState(
  //   cookies.get('changeDashTime') ? cookies.get('changeDashTime') : 0
  // );
  const [tvNumber, setTvNumber] = useState(
    cookies.get('tvNum') ? cookies.get('tvNum') : 0
  );
  const [displaysToRotate, setDisplaysToRotate] = useState(
    cookies.get('dashsToRotate') ? cookies.get('dashsToRotate') : []
  );

  const handleIsDisplayChange = (isChange) => {
    setIsDisplayChange(isChange);
    cookies.set('isDisplayChange', isChange, {
      path: '/',
    });
    cookies.set("changeDashTime", 300, {
      path: "/",
    });
  };

  // const handleTimeChange = (time) => {
  //   setTimeChange(time);
  //   cookies.set('changeDashTime', time, {
  //     path: '/',
  //   });
  //   //setear cookie
  // };

  const handleTVNumber = (tvnum) => {
    setTvNumber(tvnum);
    cookies.set('tvNum', tvnum, {
      path: '/',
    });
  };

  let TMPDashsRotate = [...displaysToRotate];

  const handleSelectedDashs = (dt, selected) => {
    if (selected) {
      TMPDashsRotate.push(dt);
    } else {
      const index = TMPDashsRotate.indexOf(dt);
      if (index > -1) {
        TMPDashsRotate.splice(index, 1);
      }
    }
    cookies.set('dashsToRotate', TMPDashsRotate, {
      path: '/',
    });
    setDisplaysToRotate(TMPDashsRotate);
  };

  const customStyles = {
    content: {
      width: '500px',
      minHeight: '160px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0px',
      transform: 'translate(-50%, -50%)',
      boxShadow: theme.shadows[5],
      overflow: 'visible',
    },
    overlay: {
      zIndex: 2000,
      backgroundColor: 'rgba(196, 196, 196, 0.5)',
    },
  };

  return (
    <Modal
      isOpen={showModalSelectPage}
      onRequestClose={closeModalSelectPage}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="ModalFilter"
    >
      <Container className={classes.modalFilterContainer}>
        <div className={classes.closeBtnModalContainer}>
          <div className={classes.closeBtnModal} onClick={closeModalSelectPage}>
            <CloseIcon />
          </div>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.modalFilterHeader}>
            <Typography className={classes.modalFilterHeaderText}>
              {`Configura la visualización de Alliot TV ${isDisplayChange}`}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.modalContent}>
            <Container maxWidth={false}>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.formConfigItem}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Pantallas cambian automáticamente"
                      labelPlacement="start"
                      checked={isDisplayChange}
                      onClick={(e) => handleIsDisplayChange(e.target.checked)}
                    />
                  </FormGroup>
                </Grid>
                {isDisplayChange && (
                  <div className={classes.configContainer}>
                    {/* <Grid item xs={12} className={classes.formConfigItem}>
                      <Typography className={classes.sectionText}>
                        Tiempo de visualización:
                      </Typography>
                      <TextField
                        id="outlined-number"
                        label="Tiempo [segundos]"
                        type="number"
                        className={classes.inputTvs}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        value={timeChange}
                        onChange={(e) => handleTimeChange(e.target.value)}
                      />
                    </Grid> */}
                    <Grid item xs={12} className={classes.formConfigItem}>
                      <Typography className={classes.sectionText}>
                        Ingresa el número de TV's que se utilizarán:
                      </Typography>
                      <TextField
                        id="outlined-number"
                        label="Número de TV's"
                        type="number"
                        className={classes.inputTvs}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        value={tvNumber}
                        onChange={(e) => handleTVNumber(e.target.value)}
                      />
                    </Grid>
                    {tvNumber < displayTitles.length && tvNumber > 1 && (
                      <Grid item xs={12} className={classes.formConfigItem}>
                        <Typography className={classes.sectionText}>
                          Selecciona las pantallas que estarán rotando
                        </Typography>
                        <FormGroup>
                          {displayTitles.map((dt, index) => (
                            <FormControlLabel
                              control={<Checkbox />}
                              label={dt.name}
                              key={`${index}-diplayToRotate`}
                              className={classes.checkBoxPages}
                              checked={
                                displaysToRotate.filter(
                                  (x) => x.index === dt.index
                                ).length > 0
                              }
                              onClick={(e) =>
                                handleSelectedDashs(dt, e.target.checked)
                              }
                            />
                          ))}
                        </FormGroup>
                      </Grid>
                    )}
                  </div>
                )}
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default ModalConfig;
