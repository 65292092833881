import React, { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../theme';
import { Helmet } from 'react-helmet';
import DashTwoBody from '../components/DashTwo/DashTwoBody';
import { connect } from 'react-redux';
import { isMembershipSelected } from '../helpers/Actions';
import SelectBuilding from '../components/SelectBuilding';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  containerContent: {
    width: 'calc(100% - 48px) !important',
    height: 'calc(100vh - 112px)',
    backgroundColor: theme.background,
    padding: '24px',
  },
}));

const DashTwo = ({ selMembership }) => {
  const classes = useStyles();
  const cookies = new Cookies();
  const navigate = useNavigate();

  const rotateDashboards = () => {
    //DisplayTitles.
    const displayRotating = cookies.get('dashsToRotate');
    const indexThisDash = displayRotating.findIndex((item, index) => {
      return item.index === 1;
    });
    displayRotating.splice(indexThisDash, 1);
    navigate(displayRotating[0].link, { replace: true });
  };

  const checkIfDashTwoRotate = () => {
    const displayRotating = cookies.get('dashsToRotate')
      ? cookies.get('dashsToRotate')
      : [];
    const flag = displayRotating.filter((d) => d.index === 1).length > 0;
    console.log('flag TWO: ', flag);

    return flag;
  };

  /* useEffect(() => {
    if (selMembership.buildingName) {
      setTitle('Monitoreo de actividad en Instalación');
    } else {
      setTitle('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selMembership]); */

  useEffect(() => {
    let mounted = true;
    if (
      cookies.get('isDisplayChange') === 'true' &&
      checkIfDashTwoRotate() &&
      parseInt(cookies.get('tvNum') ? cookies.get('tvNum') : 0) === 2
    ) {
      setInterval(() => {
        if (mounted) {
          rotateDashboards();
        }
      }, 330 * 1000);
    }

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selMembership]);

  return (
    <>
      <Helmet>
        <title>Alliot TV</title>
      </Helmet>
      <Box className={classes.containerContent}>
        <Container maxWidth={false}>
          {isMembershipSelected() ? <DashTwoBody /> : <SelectBuilding />}
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  selMembership: state.membershipSelectedRed.membership,
});

export default connect(mapStateToProps, null)(DashTwo);
