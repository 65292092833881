import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AssetsInLoan from './AssetsInLoan';
import LoansInProgress from './LoansInProgress';
import RankingUsersByLoan from './RankingUsersByLoan';
import TopUsersWDelays from './TopUsersWDelays';
import { makeStyles } from '@mui/styles';
import * as DashOneServices from '../../services/dashone';

const useStyles = makeStyles(() => ({
  dashSection: {
    margin: '0px',
    borderBottom: '3px solid rgba(255, 255, 255, 0.65)',
    color: '#7F7F7F',
  },
  boxDashContainer: {
    margin: '0px',
  },
  boxDash: {
    padding: '20px !important',
  },
}));

const DashOneBody = ({ selMembership, setTitle }) => {
  const classes = useStyles();
  const [dashOneData, setDashOneData] = useState(null);

  const getDashOneData = () => {
    setTitle('Cargando datos...');
    DashOneServices.getDataDashOne(
      selMembership.buildingId,
      selMembership.memId
    )
      .then((dashonedata) => {
        setDashOneData(dashonedata.data);
        setTitle(
          'Resúmen de prestamos en curso y ranking de morosidad de usuarios'
        );
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  useEffect(() => {
    getDashOneData();
    const interval = setInterval(() => {
      getDashOneData();
    }, 1800000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selMembership]);

  return (
    <Grid container spacing={1}>
      <Grid container spacing={1} className={classes.dashSection}>
        <Grid item xs={7} className={classes.boxDash}>
          <AssetsInLoan
            assets_borrowed={dashOneData ? dashOneData.assets_borrowed : 0}
            borrow_intervals={dashOneData ? dashOneData.borrow_intervals : null}
          />
        </Grid>
        <Grid item xs={5} className={classes.boxDash}>
          <LoansInProgress
            items_borrowed={dashOneData ? dashOneData.items_borrowed : 0}
            users_with_borrowed_assets={
              dashOneData ? dashOneData.users_with_borrowed_assets : 0
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.boxDashContainer}>
        <Grid item xs={selMembership.buildingId !== 72 && selMembership.buildingId !== 73 ?  6 : 12} className={classes.boxDash}>
          <TopUsersWDelays
            top_10_users_with_delay={
              dashOneData ? dashOneData.top_10_users_with_delay : []
            }
          />
        </Grid>
        {selMembership.buildingId !== 72 && selMembership.buildingId !== 73 && (
          <Grid item xs={6} className={classes.boxDash}>
            <RankingUsersByLoan
              users_with_borrowed_ranking={
                dashOneData ? dashOneData.users_with_borrowed_ranking : []
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  selMembership: state.membershipSelectedRed.membership,
  //title: state.dashTitleRed.title,
});

const mapDispatchToProps = (dispatch) => ({
  setTitle(newTitle) {
    dispatch({
      type: 'SET_DASHBOARD_TITLE',
      title: newTitle,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashOneBody);
