import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import InventoryState from './InventoryState';
import AssetsNotAvailable from './AssetsNotAvailable';
import BuildingActivity from './BuildingActivity';
import * as DashTwoServices from '../../services/dashtwo';

const useStyles = makeStyles(() => ({
  dashSection: {
    margin: '0px',
    borderBottom: '3px solid rgba(255, 255, 255, 0.65)',
  },
  boxDashContainer: {
    margin: '0px',
  },
  boxDash: {
    padding: '20px !important',
  },
}));

const DashTwoBody = ({ selMembership, setTitle }) => {
  const classes = useStyles();

  const [dashTwoData, setDashTwoData] = useState(null);

  const getDashTwoData = () => {
    setTitle('Cargando datos...');
    DashTwoServices.getDataDashTwo(
      selMembership.buildingId,
      selMembership.memId
    )
      .then((dashtwodata) => {
        setDashTwoData(dashtwodata.data.inventory);
        setTitle('Monitoreo de actividad en Instalación');
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  useEffect(() => {
    getDashTwoData();
    const interval = setInterval(() => {
      getDashTwoData();
    }, 1800000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selMembership]);

  return (
    <Grid container spacing={1}>
      <Grid container spacing={1} className={classes.dashSection}>
        <Grid item xs={8} className={classes.boxDash}>
          <InventoryState
            items_with_available_stock={
              dashTwoData ? dashTwoData.items_with_available_stock : null
            }
            assets_available={dashTwoData ? dashTwoData.assets_available : null}
          />
        </Grid>
        <Grid item xs={4} className={classes.boxDash}>
          <AssetsNotAvailable
            assets_not_available={
              dashTwoData ? dashTwoData.assets_not_available : null
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.boxDashContainer}>
        <Grid item xs={12} className={classes.boxDash}>
          <BuildingActivity
            clients_served={
              dashTwoData ? dashTwoData.building_activity.clients_served : null
            }
            daily_deliveries_consumables={
              dashTwoData
                ? dashTwoData.building_activity.daily_deliveries_consumables
                : null
            }
            daily_assets_borrowed={
              dashTwoData
                ? dashTwoData.building_activity.daily_assets_borrowed
                : null
            }
            daily_asset_returns={
              dashTwoData
                ? dashTwoData.building_activity.daily_asset_returns
                : null
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  selMembership: state.membershipSelectedRed.membership,
});

const mapDispatchToProps = (dispatch) => ({
  setTitle(newTitle) {
    dispatch({
      type: 'SET_DASHBOARD_TITLE',
      title: newTitle,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashTwoBody);
